import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                viortis: '#1D6D7D',
                viortisLight: '#47A4A9'
            },
            dark: {
                viortis: '#1D6D7D',
                viortisLight: '#47A4A9'
            },
        },

    }
});
