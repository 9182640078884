export default function (instance) {
    return {
        Get(query) {
            return instance.get(`/CalcHistory`, { params: query });
        },
        Add(payload) {
            return instance.post('/CalcHistory', payload);
        },
        Delete(query) {
            return instance.delete('/CalcHistory', { params: query });
        },
        Calc(query) {
            return instance.post(`/CalcHistory/Calc?calcId=${query.calcId}`);
        },
        Bp(query) {
            return instance.get(`/CalcHistory/Bp?calcId=${query.calcId}`, { responseType: 'blob' });
        },
    }
}
