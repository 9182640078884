import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://calcapi.provisor.kz',
    headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('identity'))?.token }
})

instance.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403) {
        if (!error.request.responseURL.includes("Login")) {
            localStorage.removeItem('identity');
            window.location.assign('/account/login');
        }
    }
})

export default instance