export default {
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_ACTION_STATUS(state, payload) {
        state.actionStatus = payload;
    },
    SET_STATE(state, payload) {
        for (const key in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, key)) {
                state[key] = payload[key];
            }
        }
    }
}