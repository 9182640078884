<template>
  <v-app style="background-color: #fafafa">
    <v-navigation-drawer v-model="drawer" app color="grey lighten-5" clipped>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="viortis"
      elevation="0"
      absolute
      clipped-left
      height="100"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">
        <v-img
          src="./assets/images/viortis-logo.png"
          max-height="80px"
          contain
        />
      </v-toolbar-title>
      <v-spacer />
      <!-- Текущий пользователь -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn large text v-if="token != null" v-bind="attrs" v-on="on">
            <v-icon left large color="white">mdi-account-circle-outline</v-icon>
            <span
              class="white--text mx-2 font-weight-bold text-caption text-capitalize"
              >{{ token.userName }}</span
            >
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="logOut()">
              <v-list-item-icon>
                <v-icon color="blue-grey darken-3"
                  >mdi-account-remove-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Выход</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!-- //Текущий пользователь -->
    </v-app-bar>
    <v-main>
      <v-container class="d-flex align-center">
        <v-icon left>mdi-subtitles-outline</v-icon>
        <span class="text-h6">{{ title }}</span>
      </v-container>
      <router-view :key="$route.fullPath" />
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Common/Snackbar.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  computed: {
    ...mapGetters({
      getToken: "auth/GET_TOKEN",
      getTitle: "common/GET_TITLE",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
    title: {
      get: function () {
        return this.getTitle;
      },
    },
  },
  data: () => ({
    drawer: false,
    menuItems: [
      {
        title: "Главная",
        icon: "mdi-home-outline",
        link: "/home",
      },
      {
        title: "Компании",
        icon: "mdi-domain",
        link: "/companies",
      },
      {
        title: "Проценты",
        icon: "mdi-percent-circle-outline",
        link: "/percents",
      },
      {
        title: "Базовые цены",
        icon: "mdi-currency-usd",
        link: "/base-prices",
      },
    ],
  }),
  methods: {
    logOut() {
      localStorage.removeItem("identity");
      var data = this.$router.resolve({
        path: "/account/login",
      });
      document.location.href = data.href;
    },
  },
};
</script>