<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="6" v-if="selectedTab == 8 || selectedTab == 4">
            <v-switch
              v-model="allCitiesAndRegions"
              label="Все города и регионы"
              color="viortis"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="selectedTab == 8 || selectedTab == 4">
            <v-switch
              v-model="allCities"
              label="Все города"
              color="viortis"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-switch
              v-model="mainCitiesAndAllRegions"
              label="Основные города и все регионы"
              color="viortis"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="selectively"
              label="Выборочно"
              color="viortis"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card elevation="0" :disabled="!selectively" class="rounded-xl">
          <v-card-title class="viortisLight white--text">
            <span class="text-h6">Города</span>
          </v-card-title>
          <v-list dense elevation="0" height="70vh" class="overflow-auto">
            <v-list-item-group
              active-class="red lighten-4"
              multiple
              v-model="selectedCities"
            >
              <template
                v-for="city in cities.filter((x) =>
                  x.accessedTabs.includes(selectedTab)
                )"
              >
                <v-list-item dense :key="city.id" :value="city.id">
                  <v-list-item-title>{{ city.name }}</v-list-item-title>
                </v-list-item>
                <v-divider :key="'dvd_cit_' + city.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card elevation="0" :disabled="!selectively" class="rounded-xl">
          <v-card-title class="viortisLight white--text">
            <span class="text-h6">Регионы</span>
          </v-card-title>
          <v-list dense elevation="0" height="70vh" class="overflow-auto">
            <v-list-item-group
              active-class="red lighten-4"
              multiple
              v-model="selectedRegions"
            >
              <template
                v-for="region in regions.filter((x) =>
                  x.accessedTabs.includes(selectedTab)
                )"
              >
                <v-list-item dense :key="region.id" :value="region.id">
                  <v-list-item-title>{{ region.name }}</v-list-item-title>
                </v-list-item>
                <v-divider :key="'dvd_reg_' + region.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CalcHistoryRegions",
  computed: {
    ...mapGetters({
      getRegions: "calcHistory/GET_REGIONS",
      getCities: "calcHistory/GET_CITIES",
      getAllCitiesAndRegions: "calcHistory/GET_ALL_CITIES_AND_REGIONS",
      getAllCities: "calcHistory/GET_ALL_CITIES",
      getMainCitiesAndAllRegions: "calcHistory/GET_MAIN_CITIES_AND_ALL_REGIONS",
      getSelectively: "calcHistory/GET_SELECTIVELY",
      getSelectedCities: "calcHistory/GET_SELECTED_CITIES",
      getSelectedRegions: "calcHistory/GET_SELECTED_REGIONS",
      getSelectedTab: "calcHistory/GET_SELECTED_TAB",
    }),
    regions: {
      get: function () {
        return this.getRegions;
      },
    },
    cities: {
      get: function () {
        return this.getCities;
      },
    },
    allCitiesAndRegions: {
      get: function () {
        return this.getAllCitiesAndRegions;
      },
      set: function (newVal) {
        this.setAllCitiesAndRegions(newVal);
      },
    },
    allCities: {
      get: function () {
        return this.getAllCities;
      },
      set: function (newVal) {
        this.setAllCities(newVal);
      },
    },
    mainCitiesAndAllRegions: {
      get: function () {
        return this.getMainCitiesAndAllRegions;
      },
      set: function (newVal) {
        this.setMainCitiesAndAllRegions(newVal);
      },
    },
    selectively: {
      get: function () {
        return this.getSelectively;
      },
      set: function (newVal) {
        this.setSelectively(newVal);
      },
    },
    selectedCities: {
      get: function () {
        return this.getSelectedCities;
      },
      set: function (newVal) {
        this.setSelectedCities(newVal);
      },
    },
    selectedRegions: {
      get: function () {
        return this.getSelectedRegions;
      },
      set: function (newVal) {
        this.setSelectedRegions(newVal);
      },
    },
    selectedTab: {
      get: function () {
        return this.getSelectedTab;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setAllCitiesAndRegions: "calcHistory/SET_ALL_CITIES_AND_REGIONS",
      setAllCities: "calcHistory/SET_ALL_CITIES",
      setMainCitiesAndAllRegions: "calcHistory/SET_MAIN_CITIES_AND_ALL_REGIONS",
      setSelectively: "calcHistory/SET_SELECTIVELY",
      setSelectedCities: "calcHistory/SET_SELECTED_CITIES",
      setSelectedRegions: "calcHistory/SET_SELECTED_REGIONS",
    }),
  },
  watch: {
    allCitiesAndRegions: function () {
      if (this.allCitiesAndRegions) {
        this.allCities = false;
        this.mainCitiesAndAllRegions = false;
        this.selectively = false;

        this.selectedCities = this.cities.map((x) => x.id);
        this.selectedRegions = this.regions.map((x) => x.id);
      }
    },
    allCities: function () {
      if (this.allCities) {
        this.allCitiesAndRegions = false;
        this.mainCitiesAndAllRegions = false;
        this.selectively = false;

        this.selectedCities = this.cities.map((x) => x.id);
        this.selectedRegions = [];
      }
    },
    mainCitiesAndAllRegions: function () {
      if (this.mainCitiesAndAllRegions) {
        this.allCitiesAndRegions = false;
        this.allCities = false;
        this.selectively = false;

        this.selectedCities = this.cities
          .filter((x) => x.isMain)
          .map((x) => x.id);
        this.selectedRegions = this.regions.map((x) => x.id);
      }
    },
    selectively: function () {
      if (this.selectively) {
        this.allCitiesAndRegions = false;
        this.allCities = false;
        this.mainCitiesAndAllRegions = false;

        this.selectedCities = [];
        this.selectedRegions = [];
      }
    },
  },
};
</script>