import Vue from "vue";


Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("kk-KZ", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
});

Vue.filter("toDecimal", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("kk-KZ", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
});

Vue.filter('toPercentage', function (value, decimals) {
    if (!value) {
        value = 0
    }

    if (!decimals) {
        decimals = 0
    }

    value = value * 100
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = value + '%'
    return value
})

export default {};