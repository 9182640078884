import index from '../api/index'

export const Get = () => {
    return new Promise((resolve) => {
        resolve(index.company.Get())
    })
}

export const Add = (query) => {
    return new Promise((resolve) => {
        resolve(index.company.Add(query))
    })
}

export const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(index.company.Delete(query))
    })
}