import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/authStore'
import common from './common/commonStore'
import requestState from './requestState/requestStateStore'
import company from './company/companyStore'
import percent from './percent/percentStore'
import basePrice from './basePrice/basePriceStore'
import calcHistory from './calcHistory/calcHistoryStore'
import calcFile from './calcFile/calcFileStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        common,
        requestState,
        company,
        percent,
        basePrice,
        calcHistory,
        calcFile
    }
})
