export default {
    GET_ITEMS(state) {
        return state.items;
    },
    GET_TABS(state) {
        return state.tabs;
    },
    GET_SELECTED_TAB(state) {
        return state.selectedTab;
    },
    GET_CUBES(state) {
        return state.cubes;
    },
    GET_SELECTED_CUBES(state) {
        return state.selectedCubes;
    },
    GET_REGIONS(state) {
        return state.regions;
    },
    GET_CITIES(state) {
        return state.cities;
    },
    GET_ALL_CITIES_AND_REGIONS(state) {
        return state.allCitiesAndRegions;
    },
    GET_ALL_CITIES(state) {
        return state.allCities;
    },
    GET_MAIN_CITIES_AND_ALL_REGIONS(state) {
        return state.mainCitiesAndAllRegions;
    },
    GET_SELECTIVELY(state) {
        return state.selectively;
    },
    GET_SELECTED_CITIES(state) {
        return state.selectedCities;
    },
    GET_SELECTED_REGIONS(state) {
        return state.selectedRegions;
    },
    GET_PERIOD_TYPES(state) {
        return state.periodTypes;
    },
    GET_SELECTED_PERIOD_TYPE(state) {
        return state.selectedPeriodType;
    },
    GET_YEARS(state) {
        return state.years;
    },
    GET_IS_STANDARD_FILE(state) {
        return state.IsStandardFile;
    },
    GET_SELECTED_COMPANY(state) {
        return state.selectedCompany;
    },
    GET_NAME(state) {
        return state.name;
    },
    GET_ACTION_STATUS(state) {
        return state.actionStatus;
    },
    GET_CATEGORIES(state) {
        return state.categories;
    },
    GET_SELECTED_CATEGORIES(state) {
        return state.selectedCategories;
    },
    GET_IS_MAX_CATEGORY(state) {
        return state.isMaxCategory;
    },
    GET_CURRENCIES(state) {
        return state.currencies;
    },
    GET_SELECTED_CURRENCY(state) {
        return state.selectedCurrency;
    },
    GET_FILE(state) {
        return state.file;
    },
}