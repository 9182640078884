import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Login from "../views/Auth/Login"

import Home from "../views/Home/Home"

import Company from "../views/Company/Home"

import Percent from "../views/Percent/Home"

import BasePrice from "../views/BasePrice/Home"

import CalcHistoryAdd from "../views/CalcHistory/Add"

import CalcFileList from "../views/CalcFile/List"

var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/home',
            component: Home
        },
        {
            path: '/companies',
            component: Company
        },
        {
            path: '/percents',
            component: Percent
        },
        {
            path: '/base-prices',
            component: BasePrice
        },
        {
            path: '/calc-history-add',
            component: CalcHistoryAdd
        },
        {
            path: '/calc-file-list/:calcId',
            component: CalcFileList
        }
    ]
})

export default router