import { Get, Add, Delete } from '../../services/companyService';

export default {
    async GET_ITEMS_FROM_API({ commit }) {
        await Get().then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ITEMS', data);
            }
        })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async ADD_FROM_API({ commit }, payload) {
        await Add(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async DELETE_FROM_API({ commit }, payload) {
        await Delete(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
}