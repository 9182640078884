<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="sendToPrimary()"
          class="mx-2"
          :disabled="
            sendToPrimaryLoading ||
            matchLoading ||
            updateLoading ||
            standardSaveLoading ||
            alternateSaveLoading
          "
          :loading="sendToPrimaryLoading"
        >
          <span>Отправить файлы в первичку</span>
          <v-icon right>mdi-file-send-outline</v-icon>
        </v-btn>
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="match()"
          class="mx-2"
          :disabled="
            sendToPrimaryLoading ||
            matchLoading ||
            updateLoading ||
            standardSaveLoading ||
            alternateSaveLoading
          "
          :loading="matchLoading"
        >
          <span>Запустить сопоставление</span>
          <v-icon right>mdi-format-letter-matches</v-icon>
        </v-btn>
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="update()"
          class="mx-2"
          :disabled="
            sendToPrimaryLoading ||
            matchLoading ||
            updateLoading ||
            standardSaveLoading ||
            alternateSaveLoading
          "
          :loading="updateLoading"
        >
          <span>Обновить статус сопоставления</span>
          <v-icon right>mdi-camera-flip-outline</v-icon>
        </v-btn>
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="openFileDialog(true)"
          class="mx-2"
          :disabled="
            sendToPrimaryLoading ||
            matchLoading ||
            updateLoading ||
            standardSaveLoading ||
            alternateSaveLoading
          "
          :loading="standardSaveLoading"
        >
          <span>Стандарт Vi-Ortis</span>
          <v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="openFileDialog(false)"
          class="mx-2"
          :disabled="
            sendToPrimaryLoading ||
            matchLoading ||
            updateLoading ||
            standardSaveLoading ||
            alternateSaveLoading
          "
          :loading="alternateSaveLoading"
        >
          <span>Альтернативный источник</span>
          <v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
        <v-btn
          small
          text
          color="viortis"
          outlined
          @click="downloadFile()"
          class="mx-2"
          :loading="downloadLoading"
          :disabled="downloadLoading"
        >
          <span>Скачать файл</span>
          <v-icon right>mdi-download-box-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <List />
    <v-row v-show="false">
      <v-col cols="12">
        <v-file-input
          ref="fileInput"
          v-model="file"
          class="rounded-xl"
          dense
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          small-chips
          hide-details
          filled
        >
        </v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import calcFileDefaultStateObj from "../../configs/calcFileDefaultState";
import calcHistoryDefaultStateObj from "../../configs/calcHistoryDefaultState";
import List from "../../components/CalcFile/List.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CalcFileView",
  components: {
    List,
  },
  computed: {
    ...mapGetters({
      getItems: "calcFile/GET_ITEMS",
      getActionStatus: "calcFile/GET_ACTION_STATUS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    calcId: 0,
    sendToPrimaryLoading: false,
    matchLoading: false,
    updateLoading: false,
    standardSaveLoading: false,
    alternateSaveLoading: false,
    file: null,
    calcFileDefaultState: calcFileDefaultStateObj,
    calcHistoryDefaultState: calcHistoryDefaultStateObj,
    downloadLoading: false,
  }),
  methods: {
    ...mapActions({
      commitTitle: "common/COMMIT_TITLE",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      getItemsFromApi: "calcFile/GET_ITEMS_FROM_API",
      sendToPrimaryFromApi: "calcFile/SEND_TO_PRIMATY_FROM_API",
      matchFromApi: "calcFile/MATCH_FROM_API",
      updateFromApi: "calcFile/UPDATE_FROM_API",
      commitIsStandardFile: "calcHistory/COMMIT_IS_STANDARD_FILE",
      addFileFromApi: "calcFile/ADD_FROM_API",
      commitFileState: "calcFile/COMMIT_STATE",
      commitHistoryState: "calcHistory/COMMIT_STATE",
      downloadFileFromApi: "calcFile/DOWNLOAD_FROM_API",
    }),
    async sendToPrimary() {
      this.sendToPrimaryLoading = true;

      this.startRequest("sendToPrimary");

      await this.sendToPrimaryFromApi({
        calcId: this.calcId,
      });

      if (this.actionStatus != null) {
        await this.getItemsIn();
      }

      this.finishRequest({
        requestName: "sendToPrimary",
        success: this.actionStatus != null,
      });

      this.sendToPrimaryLoading = false;
    },
    async match() {
      this.matchLoading = true;

      this.startRequest("match");

      await this.matchFromApi({
        calcId: this.calcId,
      });

      if (this.actionStatus != null) {
        await this.getItemsIn();
      }

      this.finishRequest({
        requestName: "match",
        success: this.actionStatus != null,
      });

      this.matchLoading = false;
    },
    async update() {
      this.updateLoading = true;

      this.startRequest("update");

      await this.updateFromApi({
        calcId: this.calcId,
      });

      if (this.actionStatus != null) {
        await this.getItemsIn();
      }

      this.finishRequest({
        requestName: "update",
        success: this.actionStatus != null,
      });

      this.updateLoading = false;
    },
    async getItemsIn() {
      this.startRequest("filesList");

      await this.getItemsFromApi({
        calcId: this.calcId,
      });

      this.finishRequest({
        requestName: "filesList",
        success: this.items != null,
      });
    },
    openFileDialog(isStandardFile) {
      this.commitIsStandardFile(isStandardFile);
      this.$refs.fileInput.$refs.input.click();
    },
    async saveFile() {
      if (this.isStandardFile) this.standardSaveLoading = true;
      else this.alternateSaveLoading = true;

      let formData = new FormData();
      formData.append("file", this.file, this.file.name);

      this.startRequest("addFile");

      await this.addFileFromApi({
        calcId: this.calcId,
        formData: formData,
      });

      if (this.actionStatus != null) {
        await this.getItemsFromApi({
          calcId: this.calcId,
        });
      }
      this.finishRequest({
        requestName: "addFile",
        success: this.actionStatus != null,
      });

      if (this.isStandardFile) this.standardSaveLoading = false;
      else this.alternateSaveLoading = false;
    },
    async downloadFile() {
      let fileId =
        this.items != null && this.items.length > 0 ? this.items[0].id : 0;

      if (fileId == 0) return;

      this.downloadLoading = true;
      await this.downloadFileFromApi({
        id: fileId,
      });
      this.downloadLoading = false;
    },
  },
  watch: {
    file: function () {
      this.saveFile();
    },
  },
  async created() {
    this.calcId = parseInt(this.$route.params.calcId, 10);
    this.commitTitle("Файлы");
    await this.getItemsIn();
  },
  beforeRouteLeave(to, from, next) {
    this.commitHistoryState(this.calcHistoryDefaultState);
    this.commitFileState(this.calcFileDefaultState);
    next();
  },
};
</script>