<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span class="text-body-1 font-weight-medium viortis--text"
          >Название</span
        >
        <v-text-field
          v-model="name"
          outlined
          dense
          hide-details
          color="viortis"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="text-body-1 font-weight-medium viortis--text"
          >Компания</span
        >
        <v-autocomplete
          v-model="selectedCompany"
          :items="companies"
          item-text="nameRu"
          item-value="id"
          outlined
          return-object
          dense
          color="viortis"
          item-color="viortis"
          hide-details
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="text-body-1 font-weight-medium viortis--text">Валюта</span>
        <v-autocomplete
          v-model="selectedCurrency"
          :items="currencies"
          item-text="name"
          item-value="id"
          outlined
          return-object
          dense
          color="viortis"
          item-color="viortis"
          hide-details
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CalcHistoryTabs",
  computed: {
    ...mapGetters({
      getCompanies: "company/GET_ITEMS",
      getSelectedCompany: "calcHistory/GET_SELECTED_COMPANY",
      getName: "calcHistory/GET_NAME",
      getCurrencies: "calcHistory/GET_CURRENCIES",
      getSelectedCurrency: "calcHistory/GET_SELECTED_CURRENCY",
    }),
    companies: {
      get: function () {
        return this.getCompanies;
      },
    },
    selectedCompany: {
      get: function () {
        return this.getSelectedCompany;
      },
      set: function (newVal) {
        return this.setSelectedCompany(newVal);
      },
    },
    name: {
      get: function () {
        return this.getName;
      },
      set: function (newVal) {
        return this.setName(newVal);
      },
    },
    currencies: {
      get: function () {
        return this.getCurrencies;
      },
    },
    selectedCurrency: {
      get: function () {
        return this.getSelectedCurrency;
      },
      set: function (newVal) {
        return this.setSelectedCurrency(newVal);
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setSelectedCompany: "calcHistory/SET_SELECTED_COMPANY",
      setName: "calcHistory/SET_NAME",
      setSelectedCurrency: "calcHistory/SET_SELECTED_CURRENCY",
    }),
  },
};
</script>