export default function (instance) {
    return {
        Add(payload) {
            return instance.post(`/CalcFile/Add?calcId=${payload.calcId}`, payload.formData);
        },
        Get(query) {
            return instance.get('/CalcFile/Get', { params: query });
        },
        SendToPrimary(query) {
            return instance.post(`/CalcFile/SendToPrimary?calcId=${query.calcId}`);
        },
        Match(query) {
            return instance.post(`/CalcFile/Match?calcId=${query.calcId}`);
        },
        Update(query) {
            return instance.put(`/CalcFile/Update?calcId=${query.calcId}`);
        },
        Delete(query) {
            return instance.delete(`/CalcFile/Delete?id=${query.id}&calcid=${query.calcId}`);
        },
        Download(query) {
            return instance.get(`/CalcFile/Download?id=${query.id}`, { responseType: 'blob' });
        },
    }
}
