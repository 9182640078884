<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-switch
          v-model="isMaxCategory"
          label="Рассчитать для категории с максимальным товарооборотом"
          color="viortis"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-xl">
          <v-card-title class="viortisLight white--text">
            <span class="text-h6">Категории</span>
          </v-card-title>
          <v-list dense elevation="0" height="40vh" class="overflow-auto">
            <v-list-item-group
              active-class="red lighten-4"
              multiple
              v-model="selectedCategories"
            >
              <template v-for="category in categories">
                <v-list-item dense :key="category.id" :value="category.id">
                  <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item>
                <v-divider :key="'dvd_cat_' + category.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CalcHistoryCategories",
  computed: {
    ...mapGetters({
      getCategories: "calcHistory/GET_CATEGORIES",
      getSelectedCategories: "calcHistory/GET_SELECTED_CATEGORIES",
      getIsMaxCategory: "calcHistory/GET_IS_MAX_CATEGORY",
    }),
    categories: {
      get: function () {
        return this.getCategories;
      },
    },
    selectedCategories: {
      get: function () {
        return this.getSelectedCategories;
      },
      set: function (newVal) {
        this.setSelectedCategories(newVal);
      },
    },
    isMaxCategory: {
      get: function () {
        return this.getIsMaxCategory;
      },
      set: function (newVal) {
        this.setIsMaxCategory(newVal);
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setSelectedCategories: "calcHistory/SET_SELECTED_CATEGORIES",
      setIsMaxCategory: "calcHistory/SET_IS_MAX_CATEGORY",
    }),
  },
};
</script>