<template>
  <v-container>
    <List />
  </v-container>
</template>
<script>
import List from "../../components/Percent/List.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PercentHomeView",
  components: {
    List,
  },
  computed: {
    ...mapGetters({
      getItems: "percent/GET_ITEMS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  methods: {
    ...mapActions({
      commitTitle: "common/COMMIT_TITLE",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      getItemsFromApi: "percent/GET_ITEMS_FROM_API",
    }),
  },
  async created() {
    this.commitTitle("Проценты и коэффициенты");

    this.startRequest("percentList");

    await this.getItemsFromApi();

    this.finishRequest({
      requestName: "percentList",
      success: this.items != null,
    });
  },
};
</script>