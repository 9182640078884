<template>
  <div>
    <v-tabs
      grow
      color="white"
      background-color="viortisLight"
      v-model="selectedTab"
    >
      <v-tab v-for="product in tabs" :key="product.id" :tab-value="product.id">
        <span>{{ product.name }}</span>
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CalcHistoryTabs",
  computed: {
    ...mapGetters({
      getTabs: "calcHistory/GET_TABS",
      getSelectedTab: "calcHistory/GET_SELECTED_TAB",
    }),
    tabs: {
      get: function () {
        return this.getTabs;
      },
    },
    selectedTab: {
      get: function () {
        return this.getSelectedTab;
      },
      set: function (newVal) {
        this.setSelectedTab(newVal);
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setSelectedTab: "calcHistory/SET_SELECTED_TAB",
    }),
  },
};
</script>