<template>
  <div>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" md="6" lg="6" xl="6">
        <span class="text-body-1 font-weight-medium viortis--text"
          >Частота обновления</span
        >
        <v-autocomplete
          v-model="selectedPeriodType"
          :items="
            periodTypes.filter((x) => x.accessedTabs.includes(selectedTab))
          "
          item-text="name"
          item-value="id"
          outlined
          return-object
          dense
          color="viortis"
          item-color="viortis"
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <span class="text-body-1 font-weight-medium viortis--text">Период</span>
        <v-row no-gutters>
          <v-col cols="6" v-for="year in years" :key="year.id">
            <v-switch
              v-model="year.isActive"
              :label="year.name"
              color="viortis"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "CalcHistoryPeriods",
  computed: {
    ...mapGetters({
      getPeriodTypes: "calcHistory/GET_PERIOD_TYPES",
      getSelectedPeriodType: "calcHistory/GET_SELECTED_PERIOD_TYPE",
      getYears: "calcHistory/GET_YEARS",
      getSelectedTab: "calcHistory/GET_SELECTED_TAB",
    }),
    periodTypes: {
      get: function () {
        return this.getPeriodTypes;
      },
    },
    selectedPeriodType: {
      get: function () {
        return this.getSelectedPeriodType;
      },
      set: function (newVal) {
        this.setSelectedPeriodType(newVal);
      },
    },
    years: {
      get: function () {
        return this.getYears;
      },
    },
    selectedTab: {
      get: function () {
        return this.getSelectedTab;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      commitYears: "calcHistory/COMMIT_YEARS",
    }),
    ...mapMutations({
      setSelectedPeriodType: "calcHistory/SET_SELECTED_PERIOD_TYPE",
    }),
  },
  watch: {
    years: {
      handler: function () {
        this.commitYears(this.years);
      },
      deep: true,
    },
  },
};
</script>