<template>
  <div>
    <v-row>
      <v-col cols="6" class="text-left">
        <v-btn text color="viortis" outlined @click="openFileDialog(true)">
          <span>Стандарт Vi-Ortis</span>
          <v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn text color="viortis" outlined @click="openFileDialog(false)">
          <span>Альтернативный источник</span>
          <v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-show="false">
      <v-col cols="12">
        <v-file-input
          ref="fileInput"
          v-model="innerFile"
          class="rounded-xl"
          dense
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          small-chips
          hide-details
          filled
        >
        </v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.isMatched`]="{ item }">
            <code v-if="item.notMatchedGoodCount == null"
              >Запустите сопоставление!</code
            >
            <code v-else>{{
              item.notMatchedGoodCount == 0 ? "Да" : "Нет"
            }}</code>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "CalcHistoryFiles",
  computed: {
    ...mapGetters({
      getRequestState: "requestState/GET_REQUEST_STATE",
      getItems: "calcFile/GET_ITEMS",
    }),
    requestState: {
      get: function () {
        return this.getRequestState("filesList");
      },
    },
    items: {
      get: function () {
        return this.getItems;
      },
    },
  },
  data: () => ({
    requestStateEnum: requestStateObj,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Название",
        align: "center",
        sortable: true,
        value: "fileName",
        class: "viortisLight white--text",
      },
      {
        text: "Тип данных",
        align: "center",
        sortable: true,
        value: "goodType.nameEn",
        class: "viortisLight white--text",
      },
      {
        text: "Сопоставлено?",
        align: "center",
        sortable: true,
        value: "isMatched",
        class: "viortisLight white--text",
      },
      {
        text: "ID Первички",
        align: "center",
        sortable: true,
        value: "loadHistoryId",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
    innerFile: null,
  }),
  methods: {
    ...mapActions({
      commitIsStandardFile: "calcHistory/COMMIT_IS_STANDARD_FILE",
      commitFile: "calcHistory/COMMIT_FILE",
    }),
    ...mapMutations({
      setFileItems: "calcFile/SET_ITEMS",
    }),
    openFileDialog(isStandardFile) {
      this.commitIsStandardFile(isStandardFile);
      this.$refs.fileInput.$refs.input.click();
    },
  },
  watch: {
    innerFile: function () {
      this.commitFile(this.innerFile);
      this.setFileItems([
        {
          id: 1,
          fileName: this.innerFile.name,
          goodType: {
            nameEn: "Unknown",
          },
          isMatched: false,
          loadHistoryId: null,
        },
      ]);
    },
  },
};
</script>