import state from './basePriceState';
import getters from './basePriceGetters';
import mutations from './basePriceMutations';
import actions from './basePriceActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}