export default function (instance) {
    return {
        Get() {
            return instance.get('/SprCompanies');
        },
        Add(payload) {
            return instance.post('/SprCompanies', payload);
        },
        Delete(query) {
            return instance.delete('/SprCompanies', { params: query });
        },
    }
}
