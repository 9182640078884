import index from '../api/index'

export const Get = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Get(query))
    })
}

export const Add = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Add(query))
    })
}

export const SendToPrimary = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.SendToPrimary(query))
    })
}

export const Match = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Match(query))
    })
}

export const Update = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Update(query))
    })
}

export const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Delete(query))
    })
}

export const Download = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcFile.Download(query))
    })
}