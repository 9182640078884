import index from '../api/index'

export const Get = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcHistory.Get(query))
    })
}

export const Add = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcHistory.Add(query))
    })
}

export const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcHistory.Delete(query))
    })
}

export const Calc = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcHistory.Calc(query))
    })
}

export const Bp = (query) => {
    return new Promise((resolve) => {
        resolve(index.calcHistory.Bp(query))
    })
}