<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <th class="text-center">Компания</th>
            <td class="text-center">{{ item.company.nameRu }}</td>
          </tr>
          <tr>
            <th class="text-center">Продукт</th>
            <td class="text-center">{{ item.reportType.nameRu }}</td>
          </tr>
          <tr>
            <th class="text-center">Доступ/Выгрузка</th>
            <td class="text-center">
              {{ item.isAccess ? "Доступ" : "Выгрузка" }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Полный рынок/Выборка</th>
            <td class="text-center">
              {{ item.isAllGoods ? "Полный рынок" : "Выборка" }}
            </td>
          </tr>
          <tr v-if="item.reportTypeId == 2">
            <th class="text-center">ЛПУ/Без ЛПУ</th>
            <td class="text-center">
              {{ item.isLpu ? "ЛПУ" : "Без ЛПУ" }}
            </td>
          </tr>
          <tr v-if="item.reportTypeId == 2">
            <th class="text-center">Каналы Tender</th>
            <td class="text-center">
              {{
                tenders
                  .filter((x) => selectedTenders.includes(x.id))
                  .map((x) => x.name)
                  .join()
              }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Регионы</th>
            <td class="text-center">
              {{
                regions
                  .filter((x) => selectedRegions.includes(x.id))
                  .map((x) => x.name)
                  .join()
              }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Частота обновления</th>
            <td class="text-center">
              {{
                periodTypes.find((x) => x.id == item.updateBreakdownTypeId).name
              }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Годы</th>
            <td class="text-center">
              {{
                years
                  .filter((x) => selectedYears.includes(x.id))
                  .map((x) => x.name)
                  .join()
              }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Категории</th>
            <td class="text-center">
              {{
                categories
                  .filter((x) => selectedCategories.includes(x.id))
                  .map((x) => x.name)
                  .join()
              }}
            </td>
          </tr>
          <tr>
            <th class="text-center">Категория с макс. товарооборотом</th>
            <td class="text-center">
              <v-icon v-if="item.isCalcMaxCat" color="green"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
            </td>
          </tr>
          <tr v-if="item.resultFormula != null">
            <th class="text-center">Формула</th>
            <td
              class="text-center"
              v-html="formatFormula(item.resultFormula)"
            ></td>
          </tr>
          <tr v-if="item.resultFormula != null">
            <th class="text-center">Формула (Текстовое представление)</th>
            <td class="text-center">
              <span
                >(share1 * basePrice1 * coef1 + ...) * UpdateBreakdownCoef *
                ShareGeoValue * YearCoef * TenderCoef * UnloadingCoef0.6</span
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "CalcHistoryAbout",
  props: {
    item: Object,
  },
  data: () => ({
    tenders: [
      {
        id: 0,
        name: "АЛО",
      },
      {
        id: 1,
        name: "Стационар",
      },
      {
        id: 2,
        name: "Ценовой",
      },
    ],
    selectedTenders: [],
    regions: [
      { id: 6, name: "Алматы" },
      { id: 7, name: "Астана" },
      { id: 17, name: "Шымкент" },
      { id: 18, name: "Караганда" },
      { id: 19, name: "Петропавловск" },
      { id: 20, name: "Актау" },
      { id: 21, name: "Актобе" },
      { id: 22, name: "Атырау" },
      { id: 23, name: "Павлодар" },
      { id: 24, name: "Тараз" },
      { id: 25, name: "Жезказган" },
      { id: 26, name: "Кокшетау" },
      { id: 27, name: "Костанай" },
      { id: 28, name: "Кызылорда" },
      { id: 29, name: "Семей" },
      { id: 30, name: "Уральск" },
      { id: 31, name: "Усть-Каменогорск" },
      { id: 32, name: "Талдыкорган" },
      { id: 1, name: "Акмолинская" },
      { id: 2, name: "Актюбинская" },
      { id: 3, name: "Алматинская" },
      { id: 4, name: "Атырауская" },
      { id: 5, name: "Восточно-Казахстанская" },
      { id: 8, name: "Жамбылская" },
      { id: 9, name: "Западно-Казахстанская" },
      { id: 10, name: "Карагандинская" },
      { id: 11, name: "Костанайская" },
      { id: 12, name: "Кызылординская" },
      { id: 13, name: "Мангыстауская" },
      { id: 14, name: "Павлодарская" },
      { id: 15, name: "Северо-Казахстанская" },
      { id: 16, name: "Туркестанская" },
      { id: 3889, name: "область Абай" },
      { id: 3890, name: "область Жетысу" },
      { id: 3892, name: "область Улытау" },
    ],
    selectedRegions: [],
    periodTypes: [
      { id: 1, name: "Декада" },
      { id: 2, name: "Месяц" },
      { id: 3, name: "Квартал" },
      { id: 4, name: "Полугодие" },
      { id: 5, name: "Год" },
    ],
    years: [
      { id: 2023, name: "2023" },
      { id: 2022, name: "2022" },
      { id: 2021, name: "2021" },
      { id: 2020, name: "2020" },
    ],
    selectedYears: [],
    categories: [
      { id: 1, name: "БАД" },
      { id: 2, name: "ДП" },
      { id: 3, name: "ИМН" },
      { id: 5, name: "ЛРС" },
      { id: 6, name: "ЛС" },
      { id: 9, name: "ТМР" },
      { id: 10, name: "СЛГ" },
      { id: 11, name: "ЛК" },
      { id: 12, name: "БК" },
    ],
    selectedCategories: [],
  }),
  methods: {
    formatFormula(formula) {
      return formula.replace(/\[(.*?)\]/g, "<b>[$1]</b>");
    },
  },
  mounted() {
    if (
      this.item.selectedTenders != null &&
      this.item.selectedTenders.length > 0
    ) {
      this.selectedTenders = this.item.selectedTenders
        .split(",")
        .map((x) => Number(x));
    }

    if (
      this.item.selectedRegions != null &&
      this.item.selectedRegions.length > 0
    ) {
      this.selectedRegions = this.item.selectedRegions
        .split(",")
        .map((x) => Number(x));
    }

    if (this.item.selectedYears != null && this.item.selectedYears.length > 0) {
      this.selectedYears = this.item.selectedYears
        .split(",")
        .map((x) => Number(x));
    }

    if (this.item.selectedCats != null && this.item.selectedCats.length > 0) {
      this.selectedCategories = this.item.selectedCats
        .split(",")
        .map((x) => Number(x));
    }
  },
};
</script>