import { Get, Add, SendToPrimary, Match, Update, Delete, Download } from '../../services/calcFileService';
import FileSaver from 'file-saver';

export default {
    async GET_ITEMS_FROM_API({ commit }, payload) {
        await Get(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ITEMS', data);
            }
        })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },
    async ADD_FROM_API({ commit }, payload) {
        await Add(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async SEND_TO_PRIMATY_FROM_API({ commit }, payload) {
        await SendToPrimary(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async MATCH_FROM_API({ commit }, payload) {
        await Match(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async UPDATE_FROM_API({ commit }, payload) {
        await Update(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    async DELETE_FROM_API({ commit }, payload) {
        await Delete(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ACTION_STATUS', data);
            }
        })
            .catch(() => {
                commit('SET_ACTION_STATUS', null);
            })
    },
    COMMIT_STATE({ commit }, payload) {
        commit('SET_STATE', payload);
    },
    async DOWNLOAD_FROM_API({ commit }, payload) {
        await Download(payload)
            .then((response) => {
                if (response.status === 200) {
                    processFileResponse(response);
                    commit('SET_ACTION_STATUS', true);
                }
            })
            .catch((error) => {
                console.error(error);
                commit('SET_ACTION_STATUS', null);
            })
    },
}

function processFileResponse(response) {
    const fileName = extractFileName(response);

    FileSaver.saveAs(response.data, fileName);
}

function extractFileName(response) {
    const contentDisposition = response.headers["content-disposition"];
    if (contentDisposition) {
        const segments = contentDisposition.split("; ");

        const fileNameEncodedSegment = segments.find((segment) => segment.startsWith("filename*=UTF-8''"));
        if (fileNameEncodedSegment) {
            const fileNameEncoded = fileNameEncodedSegment.split("''")[1];
            const fileName = decodeURIComponent(fileNameEncoded);

            return fileName;
        }

        const fileNameSegment = segments.find((segment) => segment.startsWith("filename="));
        if (fileNameSegment) {
            const fileName = fileNameSegment.split("=")[1];

            return fileName;
        }
    }
}