import { Get } from '../../services/basePriceService';

export default {
    async GET_ITEMS_FROM_API({ commit }) {
        await Get().then(({ data, status }) => {
            if (status === 200) {
                commit('SET_ITEMS', data);
            }
        })
            .catch(() => {
                commit('SET_ITEMS', null);
            })
    },

}