<template>
  <div>
    <div v-if="!loading">
      <div class="text-right">
        <v-btn
          class="viortis white--text rounded-xl text-capitalize mb-2"
          to="calc-history-add"
          elevation="0"
          small
        >
          Добавить новый расчет
        </v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="items.data || []"
        class="elevation-0 rounded-xl outlined-table"
        :loading="requestState === requestStateEnum.PENDING"
        disable-pagination
        hide-default-footer
        fixed-header
        height="70vh"
      >
        <template slot="progress">
          <v-progress-linear color="red" indeterminate></v-progress-linear>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          <span>{{ new Date(item.dateCreated).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.dateCalc`]="{ item }">
          <span v-if="item.dateCalc != null">{{
            new Date(item.dateCalc).toLocaleString()
          }}</span>
          <code v-else>Не расчитан</code>
        </template>

        <template v-slot:[`item.resultUsd`]="{ item }">
          <span>{{ item.resultUsd | toCurrency }}</span>
        </template>

        <template v-slot:[`item.shareGoodsValue`]="{ item }">
          <span>{{
            item.shareGoodsValue
              ?.split(" ")
              .map((x) => parseFloat(x.replace(",", ".")).toFixed(2))
              .join(" ")
          }}</span>
        </template>

        <!-- Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="viortis" icon small v-bind="attrs" v-on="on">
                <v-icon> mdi-arrow-right-thin-circle-outline </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item :disabled="loading" @click="calculateItem(item)">
                  <v-list-item-icon>
                    <v-icon color="viortis">mdi-calculator</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span v-if="item.dateCalc == null">Расчитать</span>
                    <span v-else>Перерасчитать</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="loading"
                  @click="downloadItem(item)"
                  v-if="item.dateCalc != null"
                >
                  <v-list-item-icon>
                    <v-icon color="blue">mdi-download-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>Выгрузить КП</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="loading"
                  :to="'calc-file-list/' + item.id"
                  v-if="!item.isAllGoods"
                >
                  <v-list-item-icon>
                    <v-icon color="orange"
                      >mdi-file-document-multiple-outline</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>Файлы</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="loading" @click="deleteItem(item)">
                  <v-list-item-icon>
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>Удалить</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="loading"
                  @click="
                    selectedItem = item;
                    dialogInfo = true;
                  "
                >
                  <v-list-item-icon>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>Информация</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <!-- Actions end -->
      </v-data-table>
      <div>
        <v-col cols="12">
          <v-card max-width="60%" elevation="0" class="mx-auto">
            <v-pagination
              v-model="pageNumber"
              :length="items.totalPages"
              circle
              color="viortis"
            ></v-pagination>
          </v-card>
        </v-col>
      </div>

      <v-dialog v-model="dialogInfo" persistent width="800">
        <v-card>
          <v-card-title class="text-h5"> Информация о расчете </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <About
                  :item="selectedItem"
                  :key="selectedItem != null ? selectedItem.id : 0"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              color="red"
              text
              @click="dialogInfo = false"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-center align-center"
      style="height: 50vh"
    >
      <v-progress-circular
        :size="64"
        color="viortis"
        indeterminate
        class="my-4"
      ></v-progress-circular>
      <span class="text-h5 font-weight-light">Выполнение операции...</span>
    </div>
  </div>
</template>
<script>
import About from "../../components/CalcHistory/About.vue";
import requestStateObj from "../../enums/RequestState";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CompanyList",
  components: {
    About,
  },
  computed: {
    ...mapGetters({
      getItems: "calcHistory/GET_ITEMS",
      getRequestState: "requestState/GET_REQUEST_STATE",
      getActionStatus: "calcHistory/GET_ACTION_STATUS",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    requestState: {
      get: function () {
        return this.getRequestState("calcHistoryList");
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    dialogInfo: false,
    selectedItem: null,
    pageNumber: 1,
    requestStateEnum: requestStateObj,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "id",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Дата создания",
        align: "center",
        sortable: false,
        value: "dateCreated",
        class: "viortisLight white--text",
      },
      {
        text: "Автор",
        align: "center",
        sortable: false,
        value: "fullName",
        class: "viortisLight white--text",
      },
      {
        text: "Название/Комментарий",
        align: "center",
        sortable: false,
        value: "calcName",
        class: "viortisLight white--text",
      },
      {
        text: "Компания",
        align: "center",
        sortable: false,
        value: "company.nameRu",
        class: "viortisLight white--text",
      },
      {
        text: "Продукт",
        align: "center",
        sortable: false,
        value: "reportType.nameRu",
        class: "viortisLight white--text",
      },
      {
        text: "Дата расчета",
        align: "center",
        sortable: false,
        value: "dateCalc",
        class: "viortisLight white--text",
      },
      {
        text: "Результат",
        align: "center",
        sortable: false,
        value: "resultUsd",
        class: "viortisLight white--text",
      },
      {
        text: "Действия",
        align: "center",
        sortable: false,
        value: "actions",
        class: "viortisLight white--text",
      },
      {
        text: "Коэф",
        align: "center",
        sortable: false,
        value: "coef",
        class: "viortisLight white--text",
      },
      {
        text: "Доля выборки (%)",
        align: "center",
        sortable: false,
        value: "shareGoodsValue",
        class: "viortisLight white--text",
      },
      {
        text: "Доля Региона (%)",
        align: "center",
        sortable: false,
        value: "shareGeoValue",
        class: "viortisLight white--text",
      },
      {
        text: "Коэф обн/разбивки (%)",
        align: "center",
        sortable: false,
        value: "updateBreakdownCoef",
        class: "viortisLight white--text",
      },
      {
        text: "Дата создания",
        align: "center",
        sortable: false,
        value: "dateCreated",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
    loading: false,
  }),
  methods: {
    ...mapActions({
      deleteFromApi: "calcHistory/DELETE_FROM_API",
      calcFromApi: "calcHistory/CALC_FROM_API",
      downloadFromApi: "calcHistory/DOWNLOAD_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      getItemsFromApi: "calcHistory/GET_ITEMS_FROM_API",
    }),
    async deleteItem(item) {
      if (confirm("Вы действительно хотите удалить запись?")) {
        this.loading = true;

        await this.deleteFromApi({
          calcId: item.id,
        });

        if (this.actionStatus != null) {
          var itemIndex = this.items.data.findIndex((x) => x.id == item.id);
          this.items.data.splice(itemIndex, 1);
        }
        this.loading = false;
      }
    },
    async calculateItem(item) {
      if (confirm("Вы действительно хотите расчитать данную запись?")) {
        this.loading = true;

        this.startRequest("calc");

        await this.calcFromApi({
          calcId: item.id,
        });

        if (this.actionStatus != null) {
          item.resultUsd = this.actionStatus.resultUsd;
          item.coef = this.actionStatus.coef;
          item.shareGoodsValue = this.actionStatus.shareGoodsValue;
          item.shareGeoValue = this.actionStatus.shareGeoValue;
          item.updateBreakdownCoef = this.actionStatus.updateBreakdownCoef;
          item.dateCalc = this.actionStatus.dateCalc;
          item.resultFormula = this.actionStatus.resultFormula;
        }

        this.finishRequest({
          requestName: "addCalc",
          success: this.actionStatus != null,
          snackbarShow: true,
          message: "Расчет завершен!",
        });

        this.loading = false;
      }
    },

    async downloadItem(item) {
      this.loading = true;

      this.startRequest("download");

      await this.downloadFromApi({
        calcId: item.id,
      });

      this.finishRequest({
        requestName: "download",
        success: this.actionStatus != null,
      });

      this.loading = false;
    },
    async getItemsInner() {
      await this.getItemsFromApi({
        pageNumber: this.pageNumber,
      });
    },
  },
  watch: {
    pageNumber: function () {
      this.getItemsInner();
    },
  },
};
</script>
<style scoped>
.outlined-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>