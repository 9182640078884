import state from './calcHistoryState';
import getters from './calcHistoryGetters';
import mutations from './calcHistoryMutations';
import actions from './calcHistoryActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}