export default function (instance) {
    return {
        Get() {
            return instance.get('/Percents');
        },
        Add(payload) {
            return instance.post('/Percents', payload);
        },
        Delete(query) {
            return instance.delete('/Percents', { params: query });
        },
    }
}
