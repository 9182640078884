import state from './calcFileState';
import getters from './calcFileGetters';
import mutations from './calcFileMutations';
import actions from './calcFileActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}