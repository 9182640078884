<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Наименование</th>
            <th class="text-center">Производитель</th>
            <th class="text-center">Сопоставлено?</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in item.filesData" :key="data.id">
            <td class="text-center">{{ data.goodName }}</td>
            <td class="text-center">{{ data.producerName }}</td>
            <td class="text-center">
              <v-icon v-if="data.goodId != null" color="green"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "CalcFileItems",
  props: {
    item: Object,
  },
  data: () => ({}),
  mounted() {},
};
</script>