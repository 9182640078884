import { Login } from '../../services/authService';

export default {
    async LOGIN_FROM_API({ commit }, payload) {
        await Login(payload).then(({ data, status }) => {
            if (status === 200) {
                commit('SET_TOKEN', data);
            }
        })
            .catch(() => {
                commit('SET_TOKEN', null);
            })
    },

}