<template>
  <div>
    <v-btn
      fab
      elevation="0"
      class="white--text mx-1 my-1 fab-btn"
      :color="cube.isActive ? 'red' : 'viortis'"
      v-for="cube in cubes.filter((x) =>
        x.availableProducts.includes(selectedTab)
      )"
      :key="cube.id"
      @click="select(cube)"
      v-show="cube.show"
    >
      <span class="text-caption">{{ cube.name }}</span>
    </v-btn>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "CalcHistoryTabs",
  computed: {
    ...mapGetters({
      getCubes: "calcHistory/GET_CUBES",
      getSelectedTab: "calcHistory/GET_SELECTED_TAB",
    }),
    cubes: {
      get: function () {
        return this.getCubes;
      },
      set: function (newVal) {
        this.setCubes(newVal);
      },
    },
    selectedTab: {
      get: function () {
        return this.getSelectedTab;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      commitCubes: "calcHistory/COMMIT_CUBES",
    }),
    ...mapMutations({
      setCubes: "calcHistory/SET_CUBES",
    }),
    select(cubeItem) {
      let isRelated = cubeItem.relatedItemId !== undefined;
      if (isRelated) {
        const relatedCube = this.cubes.find(
          (x) => x.id === cubeItem.relatedItemId
        );
        if (relatedCube) relatedCube.isActive = false;
      }
      cubeItem.isActive = isRelated || !cubeItem.isActive;

      if (cubeItem.id == 2 && cubeItem.isActive) {
        let fullMarket = this.cubes.find((x) => x.id == 3);
        fullMarket.isActive = false;
        fullMarket.show = false;

        let selectMarket = this.cubes.find((x) => x.id == 4);
        selectMarket.isActive = true;
        selectMarket.show = true;
      } else if (cubeItem.id == 1 && cubeItem.isActive) {
        let fullMarket = this.cubes.find((x) => x.id == 3);
        fullMarket.isActive = true;
        fullMarket.show = true;

        let selectMarket = this.cubes.find((x) => x.id == 4);
        selectMarket.isActive = false;
        selectMarket.show = true;
      }

      this.commitCubes(this.cubes);
    },
  },
};
</script>
<style scoped>
.fab-btn {
  width: 100px;
  height: 100px;
  font-size: 8pt;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  white-space: pre-line;
  word-break: break-word;
  display: inline-block;
}
</style>