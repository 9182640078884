<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.isMatched`]="{ item }">
            <code v-if="item.notMatchedGoodCount == null"
              >Запустите сопоставление!</code
            >
            <code v-else>{{
              item.notMatchedGoodCount == 0 ? "Да" : "Нет"
            }}</code>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              fab
              text
              elevation="0"
              @click="deleteItem(item)"
              color="red"
              :loading="loading"
              :disabled="loading"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              x-small
              fab
              text
              elevation="0"
              @click="
                dialogInfo = true;
                selectedItem = item;
              "
              color="black"
              :loading="loading"
              :disabled="loading"
            >
              <v-icon>mdi-arrow-right-thin-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogInfo" width="800">
      <v-card>
        <v-card-title class="text-h5"> Позиции в файле </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <Items
                :item="selectedItem"
                :key="selectedItem != null ? selectedItem.id : 0"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="red"
            text
            @click="dialogInfo = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import Items from "./Items.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CalcHistoryFiles",
  components: {
    Items,
  },
  computed: {
    ...mapGetters({
      getRequestState: "requestState/GET_REQUEST_STATE",
      getItems: "calcFile/GET_ITEMS",
      getActionStatus: "calcFile/GET_ACTION_STATUS",
    }),
    requestState: {
      get: function () {
        return this.getRequestState("filesList");
      },
    },
    items: {
      get: function () {
        return this.getItems;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    dialogInfo: false,
    selectedItem: null,
    requestStateEnum: requestStateObj,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Название",
        align: "center",
        sortable: true,
        value: "fileName",
        class: "viortisLight white--text",
      },
      {
        text: "Тип данных",
        align: "center",
        sortable: true,
        value: "goodType.nameEn",
        class: "viortisLight white--text",
      },
      {
        text: "Сопоставлено?",
        align: "center",
        sortable: true,
        value: "isMatched",
        class: "viortisLight white--text",
      },
      {
        text: "ID Первички",
        align: "center",
        sortable: true,
        value: "loadHistoryId",
        class: "viortisLight white--text",
      },
      {
        text: "Действия",
        align: "center",
        sortable: true,
        value: "actions",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
    loading: false,
  }),
  methods: {
    ...mapActions({
      deleteFromApi: "calcFile/DELETE_FROM_API",
    }),
    async deleteItem(item) {
      this.loading = true;

      await this.deleteFromApi({
        id: item.id,
        calcId: item.calcId,
      });

      if (this.actionStatus != null) {
        var itemIndex = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(itemIndex, 1);
      }

      this.loading = false;
    },
  },
  watch: {
    selectedItem: function () {
      console.log(this.selectedItem);
    },
  },
};
</script>