import state from './companyState';
import getters from './companyGetters';
import mutations from './companyMutations';
import actions from './companyActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}