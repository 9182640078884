<template>
  <v-container class="mb-2">
    <Tabs />
    <v-row>
      <v-col cols="12">
        <Cubes />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <Regions />
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <div>
          <v-row>
            <v-col cols="12">
              <Periods />
            </v-col>
          </v-row>
        </div>
        <div v-if="this.cubes.find((x) => x.id == 3).isActive">
          <v-row>
            <v-col cols="12">
              <Categories />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12">
              <Files />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <Info />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              class="viortis white--text rounded-xl text-capitalize"
              elevation="0"
              @click="save()"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import calcHistoryDefaultStateObj from "../../configs/calcHistoryDefaultState";
import calcFileDefaultStateObj from "../../configs/calcFileDefaultState";
import Tabs from "../../components/CalcHistory/Tabs.vue";
import Cubes from "../../components/CalcHistory/Cubes.vue";
import Regions from "../../components/CalcHistory/Regions.vue";
import Periods from "../../components/CalcHistory/Periods.vue";
import Files from "../../components/CalcHistory/Files.vue";
import Info from "../../components/CalcHistory/Info.vue";
import Categories from "../../components/CalcHistory/Categories.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CalcHistoryAddView",
  components: {
    Tabs,
    Cubes,
    Regions,
    Periods,
    Files,
    Info,
    Categories,
  },
  computed: {
    ...mapGetters({
      getSelectedCompany: "calcHistory/GET_SELECTED_COMPANY",
      getName: "calcHistory/GET_NAME",
      getSelectedTab: "calcHistory/GET_SELECTED_TAB",
      getCubes: "calcHistory/GET_CUBES",
      getAllCitiesAndRegions: "calcHistory/GET_ALL_CITIES_AND_REGIONS",
      getAllCities: "calcHistory/GET_ALL_CITIES",
      getMainCitiesAndAllRegions: "calcHistory/GET_MAIN_CITIES_AND_ALL_REGIONS",
      getSelectively: "calcHistory/GET_SELECTIVELY",
      getSelectedCities: "calcHistory/GET_SELECTED_CITIES",
      getSelectedRegions: "calcHistory/GET_SELECTED_REGIONS",
      getSelectedPeriodType: "calcHistory/GET_SELECTED_PERIOD_TYPE",
      getYears: "calcHistory/GET_YEARS",
      getSelectedCategories: "calcHistory/GET_SELECTED_CATEGORIES",
      getIsMaxCategory: "calcHistory/GET_IS_MAX_CATEGORY",
      getSelectedCurrency: "calcHistory/GET_SELECTED_CURRENCY",
      getActionStatus: "calcHistory/GET_ACTION_STATUS",
      getFileActionStatus: "calcFile/GET_ACTION_STATUS",
      getFile: "calcHistory/GET_FILE",
    }),
    selectedCompany: {
      get: function () {
        return this.getSelectedCompany;
      },
    },
    name: {
      get: function () {
        return this.getName;
      },
    },
    selectedTab: {
      get: function () {
        return this.getSelectedTab;
      },
    },
    cubes: {
      get: function () {
        return this.getCubes;
      },
    },
    allCitiesAndRegions: {
      get: function () {
        return this.getAllCitiesAndRegions;
      },
    },
    allCities: {
      get: function () {
        return this.getAllCities;
      },
    },
    mainCitiesAndAllRegions: {
      get: function () {
        return this.getMainCitiesAndAllRegions;
      },
    },
    selectively: {
      get: function () {
        return this.getSelectively;
      },
    },
    selectedCities: {
      get: function () {
        return this.getSelectedCities;
      },
    },
    selectedRegions: {
      get: function () {
        return this.getSelectedRegions;
      },
    },
    selectedPeriodType: {
      get: function () {
        return this.getSelectedPeriodType;
      },
    },
    years: {
      get: function () {
        return this.getYears;
      },
    },
    selectedCategories: {
      get: function () {
        return this.getSelectedCategories;
      },
    },
    isMaxCategory: {
      get: function () {
        return this.getIsMaxCategory;
      },
    },
    selectedCurrency: {
      get: function () {
        return this.getSelectedCurrency;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    fileActionStatus: {
      get: function () {
        return this.getFileActionStatus;
      },
    },
    file: {
      get: function () {
        return this.getFile;
      },
    },
  },
  data: () => ({
    calcHistoryDefaultState: calcHistoryDefaultStateObj,
    calcFileDefaultState: calcFileDefaultStateObj,
  }),
  methods: {
    ...mapActions({
      commitTitle: "common/COMMIT_TITLE",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      getCompaniesFromApi: "company/GET_ITEMS_FROM_API",
      addFromApi: "calcHistory/ADD_FROM_API",
      addFileFromApi: "calcFile/ADD_FROM_API",
      getFileItemsFromApi: "calcFile/GET_ITEMS_FROM_API",
      commitHistoryState: "calcHistory/COMMIT_STATE",
      commitFileState: "calcFile/COMMIT_STATE",
    }),
    async saveFile() {
      if (this.file == null) return;

      let formData = new FormData();
      formData.append("file", this.file, this.file.name);

      this.startRequest("filesList");

      await this.addFileFromApi({
        calcId: this.actionStatus,
        formData: formData,
      });

      if (this.fileActionStatus != null) {
        await this.getFileItemsFromApi({
          calcId: this.actionStatus,
        });
      }
      this.finishRequest({
        requestName: "filesList",
        success: this.fileActionStatus != null,
      });
    },
    async save() {
      var obj = {
        calcName: this.name,
        companyId: this.selectedCompany.id,
        reportTypeId: this.selectedTab,
        isAllGoods: this.cubes.find((x) => x.id == 3).isActive,
        isAccess: this.cubes.find((x) => x.id == 1).isActive,
        isLpu:
          this.selectedTab == 2
            ? this.cubes.find((x) => x.id == 5).isActive
            : null,
        selectedTenders:
          this.selectedTab == 2
            ? this.cubes
                .filter((x) => x.isTender && x.isActive)
                .map((x) => x.tenderId)
                .join()
            : null,
        selectedCats: this.selectedCategories.join(),
        isCalcMaxCat: this.cubes.find((x) => x.id == 3).isActive
          ? this.isMaxCategory
          : false,
        regionsTypeId: this.allCitiesAndRegions
          ? 0
          : this.mainCitiesAndAllRegions
          ? 1
          : this.allCities
          ? 2
          : 3,
        selectedRegions: this.selectedRegions
          .concat(this.selectedCities)
          .join(),
        updateBreakdownTypeId: this.selectedPeriodType.id,
        isAllYears: this.cubes.find((x) => x.id == 3).isActive
          ? true
          : this.years.every((x) => x.isActive),
        selectedYears: this.years
          .filter((x) => x.isActive)
          .map((x) => x.id)
          .join(),
        ccyName: this.selectedCurrency.name,
      };

      this.startRequest("addCalc");

      await this.addFromApi(obj);

      if (this.actionStatus != null) {
        await this.saveFile();
      }

      this.finishRequest({
        requestName: "addCalc",
        success: this.actionStatus != null,
        snackbarShow: true,
        message: "Расчет добавлен!",
      });

      if (this.actionStatus != null) {
        this.commitHistoryState(this.calcHistoryDefaultState);
        this.commitFileState(this.calcFileDefaultState);
        this.$router.go(-1);
      }
    },
  },
  async created() {
    this.commitTitle("Добавить новый расчет");
    await this.getCompaniesFromApi();
  },
  beforeRouteLeave(to, from, next) {
    this.commitHistoryState(this.calcHistoryDefaultState);
    this.commitFileState(this.calcFileDefaultState);
    next();
  },
};
</script>