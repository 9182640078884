<template>
  <div>
    <!-- Geomarketing -->
    <v-row>
      <v-col cols="12">
        <span class="text-h5">Geomarketing</span>
        <v-data-table
          :headers="headersGeomarketing"
          :items="items.filter((x) => x.reportType.id == 8) || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
          disable-pagination
          hide-default-footer
          fixed-header
          height="100%"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Geomarketing end-->

    <!-- Sell In -->
    <v-row>
      <v-col cols="12">
        <span class="text-h5">Sell In</span>
        <v-data-table
          :headers="headersSellin"
          :items="items.filter((x) => x.reportType.id == 1) || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
          disable-pagination
          hide-default-footer
          fixed-header
          height="100%"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Sell In end-->

    <!-- Sell Out -->
    <v-row>
      <v-col cols="12">
        <span class="text-h5">Sell Out</span>
        <v-data-table
          :headers="headersSellout"
          :items="items.filter((x) => x.reportType.id == 4) || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
          disable-pagination
          hide-default-footer
          fixed-header
          height="100%"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Sell Out end-->

    <!-- Tender-->
    <v-row>
      <v-col cols="12">
        <span class="text-h5">Tender</span>
        <v-data-table
          :headers="headersTender"
          :items="items.filter((x) => x.reportType.id == 2) || []"
          class="elevation-0 rounded-xl outlined-table"
          :loading="requestState === requestStateEnum.PENDING"
          disable-pagination
          hide-default-footer
          fixed-header
          height="100%"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Tender end-->
  </div>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import { mapGetters } from "vuex";
export default {
  name: "BasePriceList",
  computed: {
    ...mapGetters({
      getItems: "basePrice/GET_ITEMS",
      getRequestState: "requestState/GET_REQUEST_STATE",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    requestState: {
      get: function () {
        return this.getRequestState("basePriceList");
      },
    },
  },
  data: () => ({
    requestStateEnum: requestStateObj,
    headersGeomarketing: [
      {
        text: "Категория",
        align: "center",
        sortable: true,
        value: "category.nameRu",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Выборка 18г  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdLight",
        class: "viortisLight white--text",
      },
      {
        text: "Выборка 18г и 17р  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsd",
        class: "viortisLight white--text ",
      },
      {
        text: "Полный рынок 18г  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFullLight",
        class: "viortisLight white--text",
      },
      {
        text: "Полный рынок 18г и17р ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFull",
        class: "viortisLight white--text  rounded-tr-xl",
      },
    ],
    headersSellin: [
      {
        text: "Категория",
        align: "center",
        sortable: true,
        value: "category.nameRu",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Выборка 4г и 17р ",
        align: "center",
        sortable: true,
        value: "basePriceUsd",
        class: "viortisLight white--text ",
      },
      {
        text: "Полный рынок 4г и17р ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFull",
        class: "viortisLight white--text  rounded-tr-xl",
      },
    ],
    headersSellout: [
      {
        text: "Категория",
        align: "center",
        sortable: true,
        value: "category.nameRu",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Выборка 18г  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdLight",
        class: "viortisLight white--text",
      },
      {
        text: "Выборка 18г и 17р  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsd",
        class: "viortisLight white--text ",
      },
      {
        text: "Полный рынок 18г  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFullLight",
        class: "viortisLight white--text",
      },
      {
        text: "Полный рынок 18г и17р ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFull",
        class: "viortisLight white--text  rounded-tr-xl",
      },
    ],
    headersTender: [
      {
        text: "Категория",
        align: "center",
        sortable: true,
        value: "category.nameRu",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Выборка без ЛПУ  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdLight",
        class: "viortisLight white--text",
      },
      {
        text: "Выборка с ЛПУ  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsd",
        class: "viortisLight white--text ",
      },
      {
        text: "Полный рынок без ЛПУ  ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFullLight",
        class: "viortisLight white--text",
      },
      {
        text: "Полный рынок с ЛПУ ($)",
        align: "center",
        sortable: true,
        value: "basePriceUsdFull",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
  }),
};
</script>
<style scoped>
.outlined-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>