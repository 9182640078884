<template>
  <div>
    <div class="text-right">
      <v-btn
        class="viortis white--text rounded-xl text-capitalize mb-2"
        elevation="0"
        small
        @click="dialogAdd = true"
      >
        Добавить
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items || []"
      class="elevation-0 rounded-xl outlined-table"
      :loading="requestState === requestStateEnum.PENDING"
      disable-pagination
      hide-default-footer
      fixed-header
      height="70vh"
    >
      <template slot="progress">
        <v-progress-linear color="red" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-center align-center">
          <v-btn
            x-small
            fab
            text
            elevation="0"
            @click="deleteItem(item)"
            color="red"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAdd" width="500">
      <v-card>
        <v-card-title class="text-h5"> Добавить новую компанию </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nameRu"
                outlined
                dense
                hide-details
                color="viortis"
                label="Наименование (RU)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nameEn"
                outlined
                dense
                hide-details
                color="viortis"
                label="Наименование (EN)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nameKz"
                outlined
                dense
                hide-details
                color="viortis"
                label="Наименование (KZ)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="viortis"
            text
            @click="addItem()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CompanyList",
  computed: {
    ...mapGetters({
      getItems: "company/GET_ITEMS",
      getActionStatus: "company/GET_ACTION_STATUS",
      getRequestState: "requestState/GET_REQUEST_STATE",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    requestState: {
      get: function () {
        return this.getRequestState("companyList");
      },
    },
  },
  data: () => ({
    loading: false,
    dialogAdd: false,
    nameRu: null,
    nameKz: null,
    nameEn: null,
    requestStateEnum: requestStateObj,
    headers: [
      {
        text: "Идентификатор",
        align: "center",
        sortable: true,
        value: "id",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Наименование",
        align: "center",
        sortable: true,
        value: "nameRu",
        class: "viortisLight white--text",
      },
      {
        text: "Действия",
        align: "center",
        sortable: false,
        value: "actions",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
  }),
  methods: {
    ...mapActions({
      addFromApi: "company/ADD_FROM_API",
      deleteFromApi: "company/DELETE_FROM_API",
      getItemsFromApi: "company/GET_ITEMS_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
    }),
    async addItem() {
      this.startRequest("addCompany");
      await this.addFromApi({
        nameRu: this.nameRu,
        nameEn: this.nameEn,
        nameKz: this.nameKz,
      });

      if (this.actionStatus != null) {
        this.dialogAdd = false;
        await this.getItemsFromApi();
      }

      this.finishRequest({
        requestName: "addCompany",
        success: this.actionStatus != null,
        snackbarShow: true,
        message: "Новая компания добавлена!",
      });
    },
    async deleteItem(item) {
      if (confirm("Вы действительно хотите удалить запись?")) {
        this.loading = true;

        await this.deleteFromApi({
          id: item.id,
        });

        if (this.actionStatus != null) {
          var itemIndex = this.items.findIndex((x) => x.id == item.id);
          this.items.splice(itemIndex, 1);
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.outlined-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>