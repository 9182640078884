<template>
  <v-card elevation="0" outlined class="rounded-xl">
    <v-card-title class="px-0 pt-0">
      <v-toolbar color="viortis" elevation="0" class="rounded-t-xl">
      </v-toolbar>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-row class="justify-center align-center">
          <v-col cols="10" class="justify-center text-center">
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
            <v-text-field
              prepend-icon="mdi-account"
              filled
              dense
              v-model="userName"
              label="Имя пользователя"
              color="viortis"
              type="text"
              class="rounded-t-xl"
              :rules="[
                userNameRequiredRule(userName),
                userNameMinLengthRule(userName),
                userNameMaxLengthRule(userName),
              ]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['userNameRequired', 'userNameMin', 'userNameMax'].includes(
                      x.ruleName
                    )
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-text-field
              prepend-icon="mdi-lock"
              filled
              dense
              v-model="password"
              label="Пароль"
              color="viortis"
              :append-icon="
                passwordShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="passwordShow ? 'text' : 'password'"
              @click:append="passwordShow = !passwordShow"
              class="rounded-t-xl"
              :rules="[
                passwordRequiredRule(password),
                passwordMinLengthRule(password),
                passwordMaxLengthRule(password),
              ]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['passwordRequired', 'passwordMin', 'passwordMax'].includes(
                      x.ruleName
                    )
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-btn
              class="viortis white--text rounded-xl mb-4 text-capitalize"
              @click="enter()"
              elevation="0"
              :loading="requestState === requestStateEnum.PENDING"
              :disabled="requestState === requestStateEnum.PENDING"
            >
              Вход
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AuthenticationFormComponent",
  components: {},
  computed: {
    ...mapGetters({
      getToken: "auth/GET_TOKEN",
      getRequestState: "requestState/GET_REQUEST_STATE",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
    requestState: {
      get: function () {
        return this.getRequestState("auth");
      },
    },
  },
  data: () => ({
    requestStateEnum: requestStateObj,
    userName: null,
    password: null,
    passwordShow: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameMin",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameMax",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
  }),
  methods: {
    ...mapActions({
      loginFromApi: "auth/LOGIN_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
      resetRequest: "requestState/RESET_REQUEST",
    }),
    async enter() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.startRequest("auth");

        await this.loginFromApi({
          UserName: this.userName,
          Password: this.password,
        });

        this.finishRequest({
          requestName: "auth",
          success: this.token != null,
          snackbarShow: true,
        });

        if (this.token != null) {
          var data = this.$router.resolve({
            path: "/home",
          });
          document.location.href = data.href;
        }
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Введите пароль!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Минимальная длина пароля 6 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text = "Максимальная длина пароля 255 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Введите имя пользователя!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameMin"
      );
      if (v != null && v.length < 3) {
        currentRule.status = false;
        currentRule.text = "Минимальная длина имени пользователя 6 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text =
          "Максимальная длина имени пользователя 255 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
  },
};
</script>