export default {
    SET_ITEMS(state, payload) {
        state.items = payload;
    },
    SET_TABS(state, payload) {
        state.tabs = payload;
    },
    SET_SELECTED_TAB(state, payload) {
        state.selectedTab = payload;
    },
    SET_CUBES(state, payload) {
        state.cubes = payload;
    },
    SET_SELECTED_CUBES(state, payload) {
        state.selectedCubes = payload;
    },
    SET_REGIONS(state, payload) {
        state.regions = payload;
    },
    SET_CITIES(state, payload) {
        state.cities = payload;
    },
    SET_ALL_CITIES_AND_REGIONS(state, payload) {
        state.allCitiesAndRegions = payload;
    },
    SET_ALL_CITIES(state, payload) {
        state.allCities = payload;
    },
    SET_MAIN_CITIES_AND_ALL_REGIONS(state, payload) {
        state.mainCitiesAndAllRegions = payload;
    },
    SET_SELECTIVELY(state, payload) {
        state.selectively = payload;
    },
    SET_SELECTED_CITIES(state, payload) {
        state.selectedCities = payload;
    },
    SET_SELECTED_REGIONS(state, payload) {
        state.selectedRegions = payload;
    },
    SET_PERIOD_TYPES(state, payload) {
        state.periodTypes = payload;
    },
    SET_SELECTED_PERIOD_TYPE(state, payload) {
        state.selectedPeriodType = payload;
    },
    SET_YEARS(state, payload) {
        state.years = payload;
    },
    SET_IS_STANDARD_FILE(state, payload) {
        state.IsStandardFile = payload;
    },
    SET_SELECTED_COMPANY(state, payload) {
        state.selectedCompany = payload;
    },
    SET_NAME(state, payload) {
        state.name = payload;
    },
    SET_ACTION_STATUS(state, payload) {
        state.actionStatus = payload;
    },
    SET_CATEGORIES(state, payload) {
        state.categories = payload;
    },
    SET_SELECTED_CATEGORIES(state, payload) {
        state.selectedCategories = payload;
    },
    SET_IS_MAX_CATEGORY(state, payload) {
        state.isMaxCategory = payload;
    },
    SET_CURRENCIES(state, payload) {
        state.currencies = payload;
    },
    SET_SELECTED_CURRENCY(state, payload) {
        state.selectedCurrency = payload;
    },
    SET_FILE(state, payload) {
        state.file = payload;
    },
    SET_STATE(state, payload) {
        for (const key in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, key)) {
                state[key] = payload[key];
            }
        }
    }
}