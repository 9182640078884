export default {
    items: null,
    tabs: [
        {
            id: 1,
            name: "Sell In",
        },
        {
            id: 2,
            name: "Tender",
        },
        {
            id: 4,
            name: "Sell Out",
        },
        {
            id: 8,
            name: "GeoMarketing",
        },
    ],
    selectedTab: 1,
    cubes: [
        {
            id: 1,
            name: "Доступ",
            isActive: true,
            availableProducts: [1, 2, 4, 8],
            relatedItemId: 2,
            show: true
        },
        {
            id: 2,
            name: "Выгрузка",
            isActive: false,
            availableProducts: [1, 2, 4, 8],
            relatedItemId: 1,
            show: true
        },
        {
            id: 3,
            name: "Полный рынок",
            isActive: true,
            availableProducts: [1, 2, 4, 8],
            relatedItemId: 4,
            show: true
        },
        {
            id: 4,
            name: "Выборка",
            isActive: false,
            availableProducts: [1, 2, 4, 8],
            relatedItemId: 3,
            show: true
        },
        {
            id: 5,
            name: "ЛПУ",
            isActive: false,
            availableProducts: [2],
            relatedItemId: 6,
            show: true
        },
        {
            id: 6,
            name: "Без ЛПУ",
            isActive: false,
            availableProducts: [2],
            relatedItemId: 5,
            show: true
        },
        {
            id: 7,
            name: "АЛО",
            isActive: false,
            availableProducts: [2],
            isTender: true,
            tenderId: 0,
            show: true
        },
        {
            id: 8,
            name: "Стационар",
            isActive: false,
            availableProducts: [2],
            isTender: true,
            tenderId: 1,
            show: true
        },
        {
            id: 9,
            name: "Ценовой",
            isActive: false,
            isTender: true,
            availableProducts: [2],
            tenderId: 2,
            show: true
        },
    ],
    selectedCubes: {
        isAccess: true,
        isAllGoods: true,
        isLpu: false,
        selectedTenders: []
    },
    cities: [
        { id: 6, name: "Алматы", isMain: true, accessedTabs: [1, 2, 4, 8] },
        { id: 7, name: "Астана", isMain: true, accessedTabs: [1, 2, 4, 8] },
        { id: 17, name: "Шымкент", isMain: true, accessedTabs: [1, 2, 4, 8] },
        { id: 18, name: "Караганда", isMain: true, accessedTabs: [1, 4, 8] },
        { id: 19, name: "Петропавловск", accessedTabs: [4, 8] },
        { id: 20, name: "Актау", accessedTabs: [4, 8] },
        { id: 21, name: "Актобе", accessedTabs: [4, 8] },
        { id: 22, name: "Атырау", accessedTabs: [4, 8] },
        { id: 23, name: "Павлодар", accessedTabs: [4, 8] },
        { id: 24, name: "Тараз", accessedTabs: [4, 8] },
        { id: 25, name: "Жезказган", accessedTabs: [4, 8] },
        { id: 26, name: "Кокшетау", accessedTabs: [4, 8] },
        { id: 27, name: "Костанай", accessedTabs: [4, 8] },
        { id: 28, name: "Кызылорда", accessedTabs: [4, 8] },
        { id: 29, name: "Семей", accessedTabs: [4, 8] },
        { id: 30, name: "Уральск", accessedTabs: [4, 8] },
        { id: 31, name: "Усть-Каменогорск", accessedTabs: [4, 8] },
        { id: 32, name: "Талдыкорган", accessedTabs: [4, 8] },
    ],
    regions: [
        { id: 1, name: "Акмолинская", accessedTabs: [1, 2, 4, 8] },
        { id: 2, name: "Актюбинская", accessedTabs: [1, 2, 4, 8] },
        { id: 3, name: "Алматинская", accessedTabs: [1, 2, 4, 8] },
        { id: 4, name: "Атырауская", accessedTabs: [1, 2, 4, 8] },
        { id: 5, name: "Восточно-Казахстанская", accessedTabs: [1, 2, 4, 8] },
        { id: 8, name: "Жамбылская", accessedTabs: [1, 2, 4, 8] },
        { id: 9, name: "Западно-Казахстанская", accessedTabs: [1, 2, 4, 8] },
        { id: 10, name: "Карагандинская", accessedTabs: [1, 2, 4, 8] },
        { id: 11, name: "Костанайская", accessedTabs: [1, 2, 4, 8] },
        { id: 12, name: "Кызылординская", accessedTabs: [1, 2, 4, 8] },
        { id: 13, name: "Мангыстауская", accessedTabs: [1, 2, 4, 8] },
        { id: 14, name: "Павлодарская", accessedTabs: [1, 2, 4, 8] },
        { id: 15, name: "Северо-Казахстанская", accessedTabs: [1, 2, 4, 8] },
        { id: 16, name: "Туркестанская", accessedTabs: [1, 2, 4, 8] },
        { id: 3889, name: "область Абай", accessedTabs: [1, 2, 4, 8] },
        { id: 3890, name: "область Жетысу", accessedTabs: [1, 2, 4, 8] },
        { id: 3892, name: "область Улытау", accessedTabs: [1, 2, 4, 8] },
    ],
    selectedCities: [],
    selectedRegions: [],
    allCitiesAndRegions: false,
    allCities: false,
    mainCitiesAndAllRegions: false,
    selectively: true,
    periodTypes: [
        { id: 1, name: 'Декада', accessedTabs: [1] },
        { id: 2, name: 'Месяц', accessedTabs: [1, 2, 4, 8] },
        { id: 3, name: 'Квартал', accessedTabs: [1, 2, 4, 8] },
        { id: 4, name: 'Полугодие', accessedTabs: [1, 2, 4, 8] },
        { id: 5, name: 'Год', accessedTabs: [1, 2, 4, 8] },
    ],
    selectedPeriodType: { id: 2, name: 'Месяц' },
    years: [
        { id: 2023, name: '2023', isActive: true },
        { id: 2022, name: '2022', isActive: true },
        { id: 2021, name: '2021', isActive: true },
        { id: 2020, name: '2020', isActive: true },
    ],
    IsStandardFile: true,
    selectedCompany: null,
    name: null,
    actionStatus: null,
    categories: [
        { id: 1, name: "БАД" },
        { id: 2, name: "ДП" },
        { id: 3, name: "ИМН" },
        { id: 5, name: "ЛРС" },
        { id: 6, name: "ЛС" },
        { id: 9, name: "ТМР" },
        { id: 10, name: "СЛГ" },
        { id: 11, name: "ЛК" },
        { id: 12, name: "БК" },
    ],
    selectedCategories: [
        6
    ],
    isMaxCategory: false,
    currencies: [
        { id: 1, name: "RUB" },
        { id: 2, name: "EUR" },
        { id: 3, name: "KZT" },
    ],
    selectedCurrency: { id: 3, name: "KZT" },
    file: null,
}