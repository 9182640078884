<template>
  <div>
    <div class="text-right">
      <v-btn
        class="viortis white--text rounded-xl text-capitalize mb-2"
        elevation="0"
        small
        @click="dialogAdd = true"
      >
        Добавить
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items || []"
      class="elevation-0 rounded-xl outlined-table"
      :loading="requestState === requestStateEnum.PENDING"
      disable-pagination
      hide-default-footer
      fixed-header
      height="70vh"
    >
      <template slot="progress">
        <v-progress-linear color="red" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-center align-center">
          <v-btn
            x-small
            fab
            text
            elevation="0"
            @click="deleteItem(item)"
            color="red"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAdd" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Добавить проценты/коэффициенты
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="percent"
                outlined
                dense
                hide-details
                color="viortis"
                label="Процент (%)"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="coef"
                outlined
                dense
                hide-details
                color="viortis"
                label="Коэффициент"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="viortis"
            text
            @click="addItem()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import requestStateObj from "../../enums/RequestState";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PercentList",
  computed: {
    ...mapGetters({
      getItems: "percent/GET_ITEMS",
      getActionStatus: "percent/GET_ACTION_STATUS",
      getRequestState: "requestState/GET_REQUEST_STATE",
    }),
    items: {
      get: function () {
        return this.getItems;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    requestState: {
      get: function () {
        return this.getRequestState("percentList");
      },
    },
  },
  data: () => ({
    loading: false,
    dialogAdd: false,
    percent: 0,
    coef: 0,
    requestStateEnum: requestStateObj,
    headers: [
      {
        text: "Процент(%)",
        align: "center",
        sortable: true,
        value: "percentValue",
        class: "viortisLight white--text rounded-tl-xl",
      },
      {
        text: "Коэффициент",
        align: "center",
        sortable: true,
        value: "coef",
        class: "viortisLight white--text",
      },
      {
        text: "Действия",
        align: "center",
        sortable: false,
        value: "actions",
        class: "viortisLight white--text rounded-tr-xl",
      },
    ],
  }),
  methods: {
    ...mapActions({
      addFromApi: "percent/ADD_FROM_API",
      deleteFromApi: "percent/DELETE_FROM_API",
      getItemsFromApi: "percent/GET_ITEMS_FROM_API",
      startRequest: "requestState/START_REQUEST",
      finishRequest: "requestState/FINISH_REQUEST",
    }),
    async addItem() {
      this.startRequest("addPercent");
      await this.addFromApi({
        percent: this.percent,
        coef: this.coef,
      });

      if (this.actionStatus != null) {
        this.dialogAdd = false;
        await this.getItemsFromApi();
      }

      this.finishRequest({
        requestName: "addPercent",
        success: this.actionStatus != null,
        snackbarShow: true,
        message: "Запись добавлена!",
      });
    },
    async deleteItem(item) {
      if (confirm("Вы действительно хотите удалить запись?")) {
        this.loading = true;

        await this.deleteFromApi({
          percent: item.percentValue,
        });

        if (this.actionStatus != null) {
          var itemIndex = this.items.findIndex(
            (x) => x.percentValue == item.percentValue
          );
          this.items.splice(itemIndex, 1);
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.outlined-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>