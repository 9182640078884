<template>
  <v-container>
    <v-row style="height: 80vh" class="d-flex align-center justify-center">
      <v-col cols="12" md="4" lg="4" xl="4">
        <div>
          <AuthenticationFormComponent />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AuthenticationFormComponent from "../../components/Account/AuthenticationFormComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "LoginView",
  components: {
    AuthenticationFormComponent,
  },
  methods: {
    ...mapActions({
      commitTitle: "common/COMMIT_TITLE",
    }),
  },
  async created() {
    this.commitTitle("Вход");
  },
};
</script>