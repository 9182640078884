import instance from './instances/instance'
import authModule from './modules/auth'
import companyModule from './modules/company'
import percentModule from './modules/percent'
import basePriceModule from './modules/basePrice'
import calcHistoryModule from './modules/calcHistory'
import calFileModule from './modules/calcFile'

export default {
    auth: authModule(instance),
    company: companyModule(instance),
    percent: percentModule(instance),
    basePrice: basePriceModule(instance),
    calcHistory: calcHistoryModule(instance),
    calcFile: calFileModule(instance)
}