import state from './percentState';
import getters from './percentGetters';
import mutations from './percentMutations';
import actions from './percentActions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}